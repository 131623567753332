














import { useFetch, defineComponent, ref } from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';
import { FooterComponents } from './FooterComponents';

export default defineComponent({
  name: 'AppFooter',
  components: {
    ...FooterComponents,
  },
  setup() {
    const { menu: footerMenu, search: searchFooterMenu } =
      useMenu('footerMenu');
    const links = ref();
    const legal = ref();
    const social = ref();
    useFetch(async () => {
      await searchFooterMenu({ code: 'footer-menu' });
      links.value = footerMenu.value?.items?.find(
        (item) => item?.uid === '66a75cbc576e292036cd8e28'
      );
      legal.value = footerMenu.value?.items?.find(
        (item) => item?.uid === '66178f54d4902872c0a18ea8'
      );
      social.value = footerMenu.value?.items?.find(
        (item) => item?.uid === '66a75cc99ccf9c2055cb305c'
      );
    });
    return {
      footerMenu,
      links,
      legal,
      social,
    };
  },
});
